.is-fixed ~ main.five-g,
.is-fixed ~ section.five-g {
  padding-top: 50px;
  margin-top: 0; }
// 在cbu將main.five-g改成div.five-g
div.five-g,
section.five-g {
  section {
    z-index: 1; }
  .fui-solution-tab {
    z-index: 2; }
  .fui-banner.is-product .slick-slider ul.slick-dots {
      bottom: 80px; }
  .fui-banner.is-product.is-style-2 .slick-slider .slick-slide img {
      height: 100%; }
  div.fui-banner.is-solution .slick-slider .slick-slide div {
      height: 100%; }
  .fui-container {
    z-index: 0; }
  .fui-banner {
    padding: 0;
    min-height: initial;
    .caption {
        a {
            height: 50px;
            line-height: 50px; } } }
  section.fui-solution-tab .fui-nav-tab.is-box-tab .text {
    font-size: 18px; }
  #video-slider {
    overflow: visible;
    margin-top: -32px;
    padding-top: 0;
    .parallax-outer {
      top: -300px; } }
  .fui-nav-tab {
    .fui-tab {
      margin-bottom: 0; } }
  .fui-cards {
    .fui-card-title {
      .text {
        font-weight: 500;
        font-size: 1.375rem;
        @include ellipsis(3); } } }
  .tab-content {
      .fui-tab-pane {
          margin-top: 12px;

          p {
              font-size: 18px;
              line-height: 32px;
              letter-spacing: 0.2px;
              color: $darkgray70;
              font-weight: 500;
              margin-bottom: 32px; }

          h2 {
              margin-top: -32px; }

          h2.pane-title {
              margin-top: 84px;
              margin-bottom: 12px; }

          .fui-cards {
              margin: 0;
              background-size: cover;
              height: 500px;
              background-position: center;
              padding: 105px 60px 0;

              &::after {
                  content: "";
                  position: absolute;
                  top: 0;
                  left: 0;
                  background: black;
                  opacity: 0.15;
                  width: 100%;
                  height: 100%;
                  z-index: 0; }
              h2, p {
                  z-index: 1; } } }

      .five-g-card {
          width: calc( 60% - 60px);
          z-index: 1;
          position: relative;
          .fui-card-content {
            height: 218px; }
          .fui-card-description {
            color: $black50;
            font-size: 16px;
            -webkit-line-clamp: 3;
            line-height: 1.75;
            letter-spacing: 0.2px;
            height: initial; } }

      .single-card {
        .fui-card {
          position: absolute;
          right: 0;
          width: 392px; } }
      .tab-content-wrapper {
          margin-right: 60px;
          width: 40%;
          z-index: 1;
          position: relative;
          h2 {
              margin-top: 40px; } } }
  .five-g-news-content {
      position: relative;
      margin: 0 0 -6px 0;
      .fui-card-content {
        padding: 60px 48px 0px; }
      .fui-card-content .fui-card-title {
        font-size: 28px;
        line-height: 1.5; }
      .five-g-bg-img {
        background-size: cover;
        background-position: center;
        height: 400px;
        width: 100%; } }
  .fui-card-content {
      min-height: 218px;
      background: $white;
      p {
        margin-bottom: 48px; } }
  .video-card {
      position: relative;
      height: 460px;
      cursor: pointer;
      .mask {
        height: 97px;
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        background-blend-mode: multiply;
        background-image: linear-gradient(to bottom, rgba(32, 32, 32, 0), rgba(32, 32, 32, 0.6));
        z-index: 0; }
      .video-img {
        height: 460px;
        width: 100%;
        background-size: cover;
        background-position: center; }
      .video-card-text {
          position: absolute;
          left: 30px;
          bottom: 20px;
          border-left: 6px solid $accent;
          color: $white;
          padding-left: 12px;
          z-index: 1; }
      &::before,&::after {
          content: "";
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          margin: auto;
          z-index: 2; }
      &::before {
          width: 60px;
          height: 60px;
          background: $white;
          opacity: .84;
          border-radius: 30px;
          box-shadow: 0 11px 48px 0 rgba(32, 32, 32, 0.3); }
      &::after {
          left: 20px;
          width: 0;
          height: 0;
          border-left: 16px solid $main;
          border-top: 12px solid transparent;
          border-bottom: 12px solid transparent;
          border-right: 16px solid transparent; } }
  .lab {
      // background-image: url(#{$publicURL}/resources/ebu/images/ebu-5g-lab-bkg-800x600.jpg)
      background-size: contain;
      width: 100%;
      height: 576px;
      background-repeat: no-repeat;
      background-color: $lightgray100;
      background-position: center right;
      padding: 124px 0 120px 0;
      .subtitle {
          max-width: 695px;
          // font-weight: 500
          line-height: 1.78;
          letter-spacing: 0.2px; }
      .d-flex {
        margin-top: 32px; }
      h2 {
          margin-top: 6px;
          margin-bottom: 30px;
          font-weight: 500; } }
  .milestone {
    .bg {
      height: 100%;
      width: 800px; }
    .slick-slider {
      margin-bottom: 0; }
    .fui-container {
        .slick-list {
            padding-top: 110px; }
        .horizontal-cards.slick-slider .slick-list .slick-slide {
            height: fit-content; }
        .milestone-card {
            background: $white;
            position: relative;
            padding: 80px 30px 42px 20px;
            ul {
                list-style: none;
                padding: 0;
                li {
                    display: flex;
                    font-weight: 500;
                    margin-bottom: 18px;
                    img {
                      // width: 24px
                      height: 24px;
                      object-fit: contain;
                      margin-right: 6px; } } }
            h4 {
                background-color: $darkgray50;
                color: $white;
                border-radius: 50%;
                width: 100px;
                height: 100px;
                display: flex;
                align-items: center;
                justify-content: center;
                position: absolute;
                left: 16px;
                top: -50px; } } } }
  .tab-ebu {
    .parallax-outer {
      z-index: -1; }
    .tab-content-wrapper {
      h2,p {
        font-weight: 400; } } }
  // CBU
  .tab-cbu {
    .parallax-outer {
      z-index: -1; }
    h2 {
      font-weight: 500; }
    .content {
      img {
        width: 110px;
        height: 110px;
        margin-bottom: 24px; }
      ul {
        padding: 0;
        list-style: none;
        li {
          display: flex;
          h3 {
            margin-bottom: 18px; }
          img {
            height: 24px;
            width: initial;
            object-fit: contain;
            margin-right: 6px; } } } }
    .cbu-expand-block {
      padding-top: 130px;
      min-height: 364px;
      display: flex;
      padding-bottom: 100px;
      .d-flex {
        flex-direction: column;
        display: flex;
        padding-right: 88px; }
      .otoro {
        img {
          max-width: 508px; } }
      .fui-card-description {
        transition: all 1s;
        overflow: hidden; }
      .fui-read-more-info {
        p {
          margin-top: 0;
          margin-bottom: 18px; } } }
    .city-bg {
      // background-image: url(#{$publicURL}/resources/cbu/images/city.png)
      width: 100%;
      height: 640px;
      margin-top: -640px;
      background-repeat-x: repeat;
      background-repeat-y: no-repeat;
      background-position: bottom;
      background-size: contain;
      position: relative;
      z-index: -1;
 }      // background-size: cover
    .panel-container {
      // background-image: url(#{$publicURL}/resources/cbu/images/ribbon-pattern-1920.jpg)
      width: 100%;
      background-color: $white;
      background-repeat: repeat;
      background-size: contain;
      .fui-panel {
        box-shadow: initial;
        background: none; } }
    .fui-panel {
      padding: 0; }
    .player-wrapper {
      padding-bottom: 120px;
      .player-block {
        display: flex;
        flex-direction: row;
        .left-block {
          min-width: 180px;
          margin-left: -34px;
          .circle-now {
            width: 90px;
            height: 90px;
            border-radius: 50%;
            background-color: #9edfe9;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 auto;
            h5 {
              margin: 0; } }
          img {
            margin-left: -10px; }
          .future {
            margin-top: -120px;
            text-align: center; }
          &::after {
            content: '';
            height: 85%;
            width: 2px;
            background-color: #eff1f2;
            z-index: -1;
            display: inline-block;
            margin-left: 89px;
            position: relative;
            margin-top: 10px; } }
        .right-block {
          display: flex;
          flex-direction: column;
          margin-top: 60px;
          padding-left: 40px; } } }
    .fui-horzonal-cards {
      margin-top: -214px;
      .fui-card {
        .fui-card-description {
          -webkit-line-clamp: initial;
          height: auto;
          overflow: visible;
          display: block;
          max-height: initial; } } } }
  .five-g-demo {
    .slick-slide > div {
      padding: 0!important; }
    .slick-arrow {
      cursor: pointer; }
    .item-bg {
      background-size: cover;
      height: 450px;
      position: relative;
      display: flex!important;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      width: 100%;
      &::after {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        background-color: #000;
        opacity: .3;
        top: 0;
        z-index: 0; }
      .shop-name {
        z-index: 1; }
      p {
        width: 100%;
        text-align: center;
        position: absolute;
        display: flex;
        align-content: center;
        justify-content: center;
        color: $white;
        z-index: 1;
        font-size: 22px; }
      .subtitle {
        font-weight: 500;
        z-index: 1; }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover; } } }
  .feature {
    .d-flex {
      flex-direction: column; } }
  .five-g-experience {
    h2 {
      width: 100%; }
    .fui-card-description {
      -webkit-line-clamp: 2!important;
      max-height: initial!important; }
    .left-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      h2 {
        margin: 0; } }
    .card-scroller {
      display: flex;
      .fui-card {
        display: flex;
        width: 50%;
        .fui-card-description {
          ul {
            list-style: none;
            padding: 0;
            li {
              display: flex;
              align-items: flex-start;
              img {
                height: 24px;
                object-fit: contain;
                margin-right: 6px!important; } } } }
        &:nth-of-type(1) {
          margin-right: 12px; } } } }
  .medical-care {
    // background-image: url(#{$publicURL}/resources/cbu/images/cbu-5-g-lab-bkg-doctor.jpg)
    background-position: right top;
    background-repeat: no-repeat;
    height: 540px;
    margin-top: 150px;
    background-size: cover;
    position: relative;
    h2 {
      padding-top: 90px;
      color: $darkgray50; }
    p {
      max-width: 50vw; } }

  .medical-card {
    background-position: top;
    background-color: #eff1f2;
    .slick-slider .slick-list .slick-slide .fui-card .fui-card-description {
      max-height: initial; }
    .fui-card {
      .fui-card-action {
        cursor: initial; }
      .fui-card-extra {
        display: none; } } }
  .gradient-bg {
    width: 100%;
    left: 0;
    top: 0;
    height: 540px;
    margin-top: -540px;
    background: linear-gradient(to left, #DAE1EC, #F2F3F5); }
  .fin-tech {
    padding-top: 68px;
    padding-bottom: 90px;
    background-color: #eff1f2;
    position: relative;
    &::before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-repeat: no-repeat;
 }      // background-image: url(#{$publicURL}/resources/cbu/images/gradient-triangle.svg)
    h2 {
      margin-top: 0!important; }
    h3 {
      padding-top: 32px; }
    .fui-card {
      margin-left: 60px;
      display: flex;
      flex-direction: column;
      align-items: center;
      float: right;
      background-color: $white;
      padding: 60px 36px;
      img {
        width: 80px;
        height: 80px;
        margin-bottom: 18px; }
      h3 {
        margin: 0;
        padding: 0; }
      p {
        font-size: 16px;
        margin-top: 24px;
        margin-bottom: 0; }
      .fui-button {
        display: none; } } }
  .fin-tech-hide {
    display: block; } }
@media screen and (max-width: 960px) {
  div.five-g,
  section.five-g {
    .tab-cbu .panel-container {
 }      // background-image: url(#{$publicURL}/resources/cbu/images/ribben-mb.svg)
    .fui-solution-tab {
      .tab-content {
        margin-top: 60px; } }
    .fui-banner.is-product .slick-slider {
      ul.slick-dots {
        bottom: 30px; }
      .slick-slide {
        min-height: initial;
        .caption, .fui-banner.is-product .slick-slider .slick-slide > div .caption {
          top: 142px; } } }
    .tab-content {
      .fui-tab-pane {
        h2 {
          margin-top: 0;
          &.pane-title {
            margin-top: 50px;
            margin-bottom: 22px; } }
        .fui-cards {
          padding: 0;
          height: 480px;
          margin-bottom: 100px!important; }
        .tab-content-wrapper {
          width: 100%;
          margin-right: 0;
          h2 {
            padding: 40px 20px 0 20px;
            font-weight: 500; }
          p {
            font-size: 16px;
            padding: 0 20px 0 20px; } } }
      .five-g-card {
        width: 100%;
        .fui-card {
          width: calc(50% - 6px);
          margin-left: 0;
          margin-right: 12px;
          &:nth-last-child(1) {
            margin-right: 0; }
          .fui-card-meta {
            margin: 0; }
          .fui-card-title {
            .text {
              font-size: 20px!important; } }
          .fui-card-description {
            height: initial;
            -webkit-line-clamp: 3;
            margin: 8px 0; } } }
      .single-card {
        .fui-card {
          width: 100%; } } }
    .fui-card-caption {
      .fui-card-content {
        min-height: initial; } }
    .five-g-news-content {
      display: block;
      .five-g-bg-img {
        height: 232px; }
      img {
          width: 100%;
          height: 230px; }
      .fui-card-content {
        padding: 30px 20px;
        p {
          margin: 8px 0 30px 0; }
        .fui-card-title {
          font-size: 22px;
          margin: 0; }
        .fui-card-meta {
          margin: 0; }
        .two-button {
          .fui-button {
            width: 50%;
            margin-right: 0; }
          .is-primary {
            margin-right: 12px; } } } }
    .fui-container .fui-cards {
      padding: 18px 15px 48px 15px; }
    .five-g-bg-img {
      height: 232px; }
    #video-slider {
      z-index: 0;
      margin-top: -46px;
      padding: 60px 0 66px 0; }
    .video-card {
      height: 420px;
      .video-img {
        height: 420px; }
      .video-card-text {
        font-size: 18px;
        font-weight: 500; } }
    .lab {
      padding: 60px 0 0;
      height: 745px;
      background-position: bottom;
      .d-flex {
        margin-top: 20px; }
      .subtitle {
        font-size: 16px; } }
    .milestone {
      .fui-container {
        .milestone-card {
          padding: 40px 30px 50px 20px;
          h4 {
            width: 80px;
            height: 80px; } }
        .slick-slide {
          width: 290px; } } }
    .tab-cbu {
      .cbu-expand-block {
        padding-top: 30px;
        padding-bottom: 100px;
        flex-direction: column;
        .d-flex {
          padding-right: 0; }
        .otoro {
          img {
            max-width: 100%; } } }
      .expand-wrapper {
        .fui-collapse-action {
          display: none; } }
      .player-wrapper {
        padding-bottom: 0;
        .player-block {
          flex-direction: column;
          .future-block.left-block {
            &::after {
              margin-left: 36px;
              margin-top: -4px; } }
          .left-block {
            margin-left: 0;
            .circle-now {
              margin-left: 0px; }
            .future {
              margin-left: 16px;
              text-align: initial;
              margin-top: -82px; }
            &::after {
              min-height: 30px;
              margin-left: 42px;
              width: 4px; } }
          .right-block {
            padding: 0;
            margin-top: 0; } } } }

    .feature {
      display: flex;
      flex-direction: row;
      img {
        margin-right: 15px; }
      h3 {
        margin-bottom: 0; } }
    .five-g-experience {
      h2 {
        text-align: left;
        width: 100%;
        font-weight: 500; }
      .card-scroller {
        display: flex;
        overflow: scroll;
        margin-left: -15px;
        margin-right: 0;
        padding: 0 15px 30px 15px;
        .fui-card {
          min-width: 270px; } }
      .left-content {
        margin-bottom: 16px; } }
    .medical-care {
      margin-top: 30px;
      background-position: top right;
      background-color: #f1f3f5;
      height: 450px;
      h2 {
        padding-top: calc(24vw + 64px);
        font-weight: 500; }
      p {
        max-width: 85%; } }
    .gradient-bg {
      display: none; }
    .medical-card {
      padding-top: 170px;
      background-color: #f1f3f5; }

    .fin-tech {
      padding-bottom: 0;
      background-color: #f1f3f5;
      padding-top: 68px;
      margin-top: 0;
      &::before {
        display: none; }
      .fui-card {
        width: 100%;
        padding: 30px 24px;
        background-color: $white;
        .fui-button {
          width: 100%;
          display: block; } } }
    .fin-tech-hide {
      display: none; }
    .fin-tech-hide.fin-tech-show {
      display: block; }
    .expand-wrapper {
      .fui-collapse-body {
        article {
          p {
            color: $black50;
            font-weight: 400; } } } }
    .fui-section-header {
      padding-top: 50px; } } }
@media screen and (max-width: 460px) {
  div.five-g,
  section.five-g {
    .tab-content .fui-tab-pane {
      .fui-cards {
        width: 100%!important;
        margin: 0 0 226px!important; }
      .city-bg {
 } }        // background-image: url(#{$publicURL}/resources/cbu/images/city-sm.png)
    .lab {
      .d-flex {
        button {
          flex: 1 1 auto; } } }
    .medical-care {
      h2 {
        padding-top: 36vw;
        padding-top: calc(24vw + 24px); }
      p {
        max-width: 80%; } } } }
