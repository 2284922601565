.align-center {
    text-align: center !important; }

.align-left {
    text-align: left !important; }

.align-right {
    text-align: right !important; }

.float-left {
    float: left !important; }

.float-right {
    float: right !important; }

.align-middle {
    vertical-align: middle !important; }

.flex-align-center {
    align-items: center !important;
    align-content: center !important; }

.flex-align-start {
    align-items: flex-start !important;
    align-content: flex-start !important; }
.flex-align-baseline {
    align-items: baseline; }
.flex-align-end {
    align-items: end !important;
    align-content: end !important; }

.justify-center {
    justify-content: center!important; }
.justify-start {
    justify-content: flex-start !important;
    justify-items: flex-start !important; }

.justify-end {
    justify-content: flex-end !important;
    justify-items: flex-end !important; }

.justify-between {
    justify-content: space-between !important;
    justify-items: space-between !important; }
.flex-column {
    flex-direction: column; }
.flex-row {
    flex-direction: row; }
@media screen and (min-width: 600px) {
    .align-center-sm {
        text-align: center !important; }

    .align-left-sm {
        text-align: left !important; }

    .align-right-sm {
        text-align: right !important; }

    .float-left-sm {
        float: left !important; }

    .float-right-sm {
        float: right !important; }

    .align-middle-sm {
        vertical-align: middle !important; }

    .flex-align-center-sm {
        align-items: center !important;
        align-content: center !important; }

    .flex-align-start-sm {
        align-items: flex-start !important;
        align-content: flex-start !important; }

    .flex-align-end-sm {
        align-items: end !important;
        align-content: end !important; }

    .justify-start-sm {
        justify-content: flex-start !important;
        justify-items: flex-start !important; }

    .justify-end-sm {
        justify-content: flex-end !important;
        justify-items: flex-end !important; }

    .justify-between-sm {
        justify-content: space-between !important;
        justify-items: space-between !important; } }

@media screen and (min-width: 960px) {
    .align-center-md {
        text-align: center !important; }

    .align-left-md {
        text-align: left !important; }

    .align-right-md {
        text-align: right !important; }

    .float-left-md {
        float: left !important; }

    .float-right-md {
        float: right !important; }

    .align-middle-md {
        vertical-align: middle !important; }

    .flex-align-center-md {
        align-items: center !important;
        align-content: center !important; }

    .flex-align-start-md {
        align-items: flex-start !important;
        align-content: flex-start !important; }

    .flex-align-end-md {
        align-items: end !important;
        align-content: end !important; }

    .justify-start-md {
        justify-content: flex-start !important;
        justify-items: flex-start !important; }

    .justify-end-md {
        justify-content: flex-end !important;
        justify-items: flex-end !important; }

    .justify-between-md {
        justify-content: space-between !important;
        justify-items: space-between !important; }
    .flex-row-md {
        flex-direction: row; } }
