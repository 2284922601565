html, body {
    margin: 0;
    padding: 0;
    font-family: $font-family;
    font-size: $font-size;
    background: $lightgray50;

    @media screen and (max-width: 768px) {
        font-size: $font-size-sm; }

    &.sidebar-open {
        overflow: hidden; }

    &.no-promotion {
        footer.fui-footer {
            .fui-section-promo {
                display: none; } } } }

.is-loading #root {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh; }

* {
    font-family: $font-family;
    box-sizing: border-box;
    outline: none; }

a, a:link, a:visited {
    display: inline-block;
    color: $black50;
    cursor: pointer;
    text-decoration: none; }

a.info-link {
    color: $accent;

    &::after {
        display: none !important; }

    &:hover {
        text-decoration: underline; }

    &:hover {
        color: $accent; } }



.hover-underline-link,
a.hover-underline-link {
    &:hover {
        text-decoration: underline; }
    &.is-text-black50 {
        &:after {
            background: $black50  !important; } } }
.hover-underline-link-accent {
    &:hover {
        opacity: 1!important;
        color: $accent!important; } }
.underline-link,
a.underline-link {
    &:after {
        content: '' !important;
        width: 100% !important;
        opacity: 1 !important;
        display: block !important;
        height: 2px !important;
        background: $main !important; }
    &.is-text-black50 {
        &:after {
            background: $black50  !important; } } }

.animate-underline,
.animation-underline,
.animate-underline-link,
.animation-underline-link,
a.animate-underline,
a.animation-underline,
a.animate-underline-link,
a.animation-underline-link {
    &::after {
        content: "";
        display: block;
        width: 0;
        height: 2px;
        background: $accent;
        opacity: 0;
        transition: all 0.3s ease; }

    &:hover::after,
    &:active::after {
        width: 100%;
        opacity: 1 !important; } }



section p {
    a, a:link, a:visited {
        &:not(.expand):not(.item):not(.fui-item):not(.fui-card):not(.fui-card-action):not(.fui-button)::after {
            content: "";
            display: block;
            width: 0;
            height: 2px;
            background: $main;
            transition: all 0.3s ease; }

        &:not(.expand):not(.item):not(.fui-item):not(.fui-card):not(.fui-card-action):not(.fui-button):hover::after,
        &:not(.expand):not(.item):not(.fui-item):not(.fui-card):not(.fui-card-action):not(.fui-button):active::after {
            width: 100%; } } }

section p {
    a, a:link, a:visited {
        &::after {
            content: "";
            display: block;
            width: 100%;
            height: 2px;
            background: $accent;
            opacity: 0; }

        &:hover::after,
        &:active::after {
            opacity: 1 !important; } } }

section.fui-section-collapse {
    a, a:link, a:visited {
        color: $accent; }
    &.is-light {
        .fui-collapse {
            color: $darkgray50;
            .collapse-header {
                color: $darkgray50;
                &::before,&::after {
                    color: $darkgray50; } } } }
    &.is-dark {
        .fui-collapse {
            color: $white;
            .collapse-header {
                color: $white;
                &::before,&::after {
                    color: $white; } } } } }

*.is-api-loading {
    position: relative;
    min-height: 300px;

    &::before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        display: block;
        background-size: 60px; } }

*.fui-button.is-api-loading {
    min-height: 0;
    pointer-events: none;

    &::before {
        background-size: 24px; } }

img {
    border: 0;

    // width: auto
 }    // height: auto

// .is-ebu,
//     a:not(.fui-item):not(.fui-button),
//     a:not(.fui-item):not(.fui-button):link,
//     a:not(.fui-item):not(.fui-button):visited
//         color: $business100
//         *
//             vertical-align: middle
//         &:hover,
//         &:active
//             color: $business50

//         &::after
//             background: $business100
//
// section p
//     a:not(.expand):not(.item):not(.fui-item):not(.fui-card):not(.fui-card-action):not(.fui-button),
//     a:not(.expand):not(.item):not(.fui-item):not(.fui-card):not(.fui-card-action):not(.fui-button):link,
//     a:not(.expand):not(.item):not(.fui-item):not(.fui-card):not(.fui-card-action):not(.fui-button):visited
//         display: inline-block
//
//         &::after
//             background: $business50

body.is-webview {
    header, footer, .fui-nav-anchor {
        display: none !important; }

    header ~ main {
        margin-top: 0 !important; } }

img {
    line-height: 0; }

hr {
    border: 0;
    border-top: solid 1px $lightgray100;
    margin-top: $space * 3;
    margin-bottom: 30px; }

body.dropdown-open {
    .fui-nav-anchor,
    .fui-nav-tab.is-content-1 {
        z-index: 19; } }

@import "./icon";
@import "./typography";
@import "./grid";
@import "./container";
@import "./display";
@import "./color";
@import "./space";
@import "./align";

