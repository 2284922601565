section.feature-banner {
  .fui-banner.is-exclusive.is-promotion {
    height: 295px;
    .slick-slide {
      height: 295px!important;
      .bg {
        height: 295px!important;
        img {
          height: 100%; } }
      .caption {
        padding-top: 0!important; }
      .description {
        margin-bottom: 36px; }
      .fui-button {
        height: 40px;
        span.text {
          line-height: 40px; } } } }
  .paper {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 0;
    font-size: 22px;
    justify-content: space-between;
    min-height: 92px;
    height: 100%;
    font-weight: 500; }
  .fui-tab-pane {
    z-index: 0;
    .MuiGrid-container .MuiGrid-item + .MuiGrid-item {
      padding: 8px;
      background: none; } }
  @media screen and ( max-width: 600px ) {
    .fui-banner.is-exclusive.is-promotion {
      height: 360px!important;
      .slick-slide {
        height: 360px!important;
        .bg {
          height: 360px!important; }
        .caption-wrapper {
          text-align: center;
          margin: 138px auto 0;
          .action {
            justify-content: center;
            // position: absolute
            width: 100%;
            margin-top: -12px; } } } } } }
