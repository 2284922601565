figure {
    width: auto;
    margin: ($space * 3) 0;
    overflow-x: auto;
    text-align: center;
    position: relative;

    &.is-inline {
        position: relative;
        display: inline-block;
        width: 100%; }

    > img {
        width: 100%;
        height: auto;
        margin: 0; }
    figcaption {
        display: inline-block;
        max-width: 894px;
        font-size: 1rem;
        text-align: left;
        padding: 12px 12px 12px 42px;
        margin-top: 18px;
        color: $navy50;
        background: $lightgray50;
        margin-bottom: 30px;

        // border: 2px solid $lightgray100

        &::before {
            content: "";
            float: left;
            margin-left: -20px;
            margin-top: 3px;
            border-top: 7px solid transparent;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-bottom: 7px solid $navy50; } }
    .tag {
        position: absolute;
        left: 0;
        top: 0 {

 }            // background-color: $coral100
        color: $white;
        font-size: 14px;
        padding: 4px 12px;

        &::after {
            border-top: 28px solid $coral100;
            content: "";
            width: 0;
            height: 0;
            border-right: 28px solid transparent;
            position: absolute;
            right: -28px;
            bottom: 0; } }

    .tag-red {
        background-color: $coral100;

        &::after {
            border-top: 28px solid $coral100;
            top: 0px; } }

    .tag-blue {
        background-color: $blue50;

        &::after {
            border-top: 28px solid $blue50;
            top: 0px; } }

    .tag-gray {
        background-color: #eff4f1;
        color: $black50;

        &::after {
            border-top: 28px solid #eff4f1;
            top: 0px; } } }

.fui-gallery {
    display: flex;
    flex-wrap: wrap;
    margin: ($space * 3) -6px;

    .fui-figure {
        width: calc(50% - 12px);
        margin: 6px;

        a:link::after,
        a:visited::after {
            display: none !important; }

        img {
            width: 100%;
            margin: 0; } } }

@media screen and (min-width: 640px) and (max-width: 768px) {
    figure.fui-figure {
        > img {
            width: auto;
            max-width: 100%; } } }

@media screen and (min-width: 960px) {
    figure.fui-figure {
        &.is-inline {
            position: relative;
            display: inline-block;
            width: calc(50% - 32px);
            margin-left: $space * 2;
            margin-right: $space * 2; }

        > img {
            max-width: 900px;
            height: auto;
            margin: 0 auto !important; }

        figcaption {
            margin-bottom: $space * 6;

 } }            // min-width: 285px

    .fui-gallery {
        margin: 0 -6px;

        a:link::after,
        a:visited::after {
            display: none; }

        .fui-figure,
        &.three-column .fui-figure {
            width: calc(33.3333% - 12px); }

        &.four-column {
            .fui-figure {
                width: calc(25% - 12px); } } } }
