// Text Color
.is-text-primary,
.is-text-main {
    color: $main !important; }

.is-text-accent {
    color: $accent !important; }

.is-text-error {
    color: $error !important; }

// Red
.is-text-red30 {
    color: $red30 !important; }

.is-text-red50 {
    color: $red50 !important; }

.is-text-red70 {
    color: $red70 !important; }

.is-text-red100 {
    color: $red100 !important; }

// Coral
.is-text-coral30 {
    color: $coral30 !important; }

.is-text-coral50 {
    color: $coral50 !important; }

.is-text-coral70 {
    color: $coral70 !important; }

.is-text-coral100 {
    color: $coral100 !important; }

.is-text-yellow30 {
    color: $yellow30 !important; }

.is-text-yellow50 {
    color: $yellow50 !important; }

.is-text-yellow70 {
    color: $yellow70 !important; }

.is-text-yellow100 {
    color: $yellow100 !important; }

.is-text-blue30 {
    color: $blue30 !important; }

.is-text-blue50 {
    color: $blue50 !important; }

.is-text-blue70 {
    color: $blue70 !important; }

.is-text-blue100 {
    color: $blue100 !important; }

.is-text-navy30 {
    color: $navy30 !important; }

.is-text-navy50 {
    color: $navy50 !important; }

.is-text-navy70 {
    color: $navy70 !important; }

.is-text-navy100 {
    color: $navy100 !important; }

.is-text-business30 {
    color: $business30 !important; }

.is-text-business50 {
    color: $business50 !important; }

.is-text-business70 {
    color: $business70 !important; }

.is-text-business100 {
    color: $business100 !important; }

.is-text-white {
    color: $white !important; }

.is-text-lightgray50 {
    color: $lightgray50 !important; }

.is-text-lightgray100 {
    color: $lightgray100 !important; }

.is-text-gray50 {
    color: $gray50 !important; }

.is-text-gray70 {
    color: $gray70 !important; }

.is-text-gray100 {
    color: $gray100 !important; }

.is-text-darkgray50 {
    color: $darkgray50 !important; }

.is-text-darkgray70 {
    color: $darkgray70 !important; }

.is-text-darkgray100 {
    color: $darkgray100 !important; }

.is-text-black50 {
    color: $black50 !important; }

.is-text-black70 {
    color: $black70 !important; }

.is-text-black100 {
    color: $black100 !important; }

.is-text-darkgreen {
    color: $darkgreen; }

.is-life-circle-gray {
    color: #5f7b80 !important; }

.is-text-grayblue {
    color: $grayblue !important; }

.is-life-circle-gray-70 {
    color: $is-life-circle-gray-70 !important; }

// Background Color
.is-bg-canary {
    background-color: #f6fb6b; }

.is-bg-initial {
    background-color: transparent !important; }

.is-bg-primary,
.is-bg-main {
    background-color: $main !important; }

.is-bg-accent {
    background-color: $accent !important; }

// Red
.is-bg-red30 {
    background-color: $red30 !important; }

.is-bg-red50 {
    background-color: $red50 !important; }

.is-bg-red70 {
    background-color: $red70 !important; }

.is-bg-red100 {
    background-color: $red100 !important; }

// Coral
.is-bg-coral30 {
    background-color: $coral30 !important; }

.is-bg-coral50 {
    background-color: $coral50 !important; }

.is-bg-coral70 {
    background-color: $coral70 !important; }

.is-bg-coral100 {
    background-color: $coral100 !important; }

.is-bg-yellow30 {
    background-color: $yellow30 !important; }

.is-bg-yellow50 {
    background-color: $yellow50 !important; }

.is-bg-yellow70 {
    background-color: $yellow70 !important; }

.is-bg-yellow100 {
    background-color: $yellow100 !important; }

.is-bg-blue30 {
    background-color: $blue30 !important; }

.is-bg-blue50 {
    background-color: $blue50 !important; }

.is-bg-blue70 {
    background-color: $blue70 !important; }

.is-bg-blue100 {
    background-color: $blue100 !important; }

.is-bg-navy30 {
    background-color: $navy30 !important; }

.is-bg-navy50 {
    background-color: $navy50 !important; }

.is-bg-navy70 {
    background-color: $navy70 !important; }

.is-bg-navy100 {
    background-color: $navy100 !important; }

.is-bg-business30 {
    background-color: $business30 !important; }

.is-bg-business50 {
    background-color: $business50 !important; }

.is-bg-business70 {
    background-color: $business70 !important; }

.is-bg-business100 {
    background-color: $business100 !important; }

.is-bg-white {
    background-color: $white !important; }

.is-bg-ice {
    background-color: $ice !important; }

.is-bg-none {
    background: none !important; }

.is-bg-lightgray50 {
    background-color: $lightgray50 !important; }

.is-bg-lightgray70 {
    background-color: $lightgray70 !important; }

.is-bg-lightgray100 {
    background-color: $lightgray100 !important; }

.is-bg-gray30 {
    background-color: $gray30 !important; }

.is-bg-gray50 {
    background-color: $gray50 !important; }

.is-bg-gray70 {
    background-color: $gray70 !important; }

.is-bg-gray100 {
    background-color: $gray100 !important; }

.is-bg-darkgray50 {
    background-color: $darkgray50 !important; }

.is-bg-darkgray70 {
    background-color: $darkgray70 !important; }

.is-bg-darkgray100 {
    background-color: $darkgray100 !important; }

.is-bg-black50 {
    background-color: $black50 !important; }

.is-bg-black70 {
    background-color: $black70 !important; }

.is-bg-black100 {
    background-color: $black100 !important; }
