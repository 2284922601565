@charset "UTF-8";

@import "./utils/variable";
@import "./utils/retina-background";

@import "./base/default";

@import "./layout/main";

@import "./state/_exclusive";

@import "./module/_tab";
@import "./module/_banner";
@import "./module/_card";
@import "./module/_button";
@import "./module/_panel";
@import "./module/_item";
@import "./module/_feature-banner";
@import "./layout/section/estore";
@import "./layout/section/5g";
@import "./layout/section/404";
@import "./layout/section/e-service";
@import "./module/_carousel";
@import "./layout/section/section";
@import "./module/_section-product-card";

.slick-slider .slick-track,
.slick-slider .slick-list {
    display: flex !important; }
