html, body {
    font-size: 16px;
    min-height: 100vh;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }

html {
    overflow-x: hidden; }

body {
    display: flex;
    flex-direction: column;
    overflow: hidden; }

.container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto; }

.is-ebu:not(.is-help-center) main {
    margin-top: 50px;

    @media screen and (min-width: 960px) {
        margin-top: 100px;

        .is-ebu:not(.is-help-center) .fui-header.is-blank main {
            margin-top: 60px; } } }
.ril-outer.ril__outer {
    z-index: 0; }
.w-100 {
    width: 100%; }
.h-100 {
    height: 100%; }
.bottom-bg {
    width: 100%;
    height: 100vh;
    // max-height: 500px
    position: absolute;
    bottom: 0;
    background-position: bottom;
    background-size: contain;
    background-repeat: no-repeat; }
main {
    display: block;
    margin-top: 50px;

    @media screen and (min-width: 960px) {
        margin-top: 100px; } }

@media screen and (min-width: 960px) {
    .is-help-center header.is-fixed ~ main {
        margin-top: 60px; } }

.promotion.expand {
    transform: scaleY(0);
    transform-origin: 50% 100%;

    > img {
        width: 100%;
        height: auto; } }

.promotion.corner-button {
    width: 58px;
    height: 58px;
    border-radius: 30px;
    border: 0;
    background: $black50;
    color: #FFF; }

.no-shadow {
    box-shadow: none!important; }
.main-shadow {
    box-shadow: 0 6px 12px 0 rgba(75, 88, 105, 0.12)!important; }

[role="button"] {
    cursor: pointer; }

@import "./header";
@import "./footer";
@import "./figure";
@import "./border";
