footer.fui-footer {
    position: relative;
    font-size: 0.98rem;
    color: #8A8A8A;
    padding: 15px 0 ($space * 1);
    background: #000000;
    z-index: 1;
    min-height: 114px;

    .links {
        display: inline-block;
        margin-top: -14px;

        a {
            color: #fff;
            padding-right: 6px;
            border-right: 1px solid #fff;
            margin-right: 6px;
            height: 16px;
            line-height: 16px;
            display: inline-block;
            vertical-align: baseline; }

        .text-sm {
            display: flex;
            margin-top: 8px; }

        small {
            line-height: 16px; } }

    a.fui-item:link,
    a.fui-item[role="button"],
    .fui-item {
        font-weight: 300;
        color: $white;

        &:hover,
        &:focus {
            color: $white;
            opacity: 0.6; } }

    p.text {
        line-height: 28px;

        * {
            vertical-align: middle; }

        i[class*='icon-'] {
            font-size: 24px; } }

    .text-gray {
        color: $gray100; }

    .footer-menu-title {
        color: $gray100;
        display: block;
        padding: $space 0; }

    .footer-menu-item {
        font-size: 14px;
        display: block;
        color: white;
        padding: ($space * 0.8) 0; }

    .MuiButton-root {
        color: #FFF; }

    .copyright {
        margin-top: $space * 4.5;
        align-content: center;
        align-items: center;
        justify-content: center;
        justify-items: center;

        img {
            width: 100px; } } }

footer.blank-footer {
    // padding-top: 24px !important

    .links {
        text-align: left; }

    .MuiGrid-root {
        align-items: center;
        margin-top: 18px;
        order: 2; }

    .copyright {
        margin-top: 0 !important;

        .MuiGrid-item > .fui-dropdown {
            margin: 0 auto;
            width: 100%; } } }



@media screen and (min-width: 960px) {
    footer.fui-footer {
        padding: 30px 0 ($space * 3);

        .fui-section-promo {
            margin-bottom: $space * 5; }

        .fui-collapse {
            margin: 0;

            .collapse-header {
                font-size: 0.875rem; } }

        .copyright {
            vertical-align: bottom;
            margin-bottom: 20px;

            img {
                margin-right: $space * 3;
                vertical-align: middle; } }

        .social-links {
            margin-top: $space * 6.5; }

        .phone-promo {
            font-size: 14px;
            margin-bottom: 0;

            em {
                font-size: 0.875rem !important; } }

        .collapse-header {
            color: $gray100;
            margin-bottom: 0; } } }

@media screen and (max-width: 960px) {
    .is-ebu footer.fui-footer,
    .is-help-center footer.fui-footer,
    footer.fui-footer {
        .copyright {
            .MuiGrid-item {
                &:nth-child(1) {
                    order: 2; }

                > * {
                    display: block;
                    text-align: center; }

                > img {
                    margin-left: auto;
                    margin-right: auto; }

                > .fui-dropdown {
                    margin-bottom: 38px;
                    width: 100%; } } }

        .phone-promo ~ p {
            font-size: 1rem; } }

    footer.blank-footer.fui-footer {
        .footer-fetnet {
            .d-flex {
                flex-direction: column; } }

        .links {
            width: 100%;
            margin-top: 0;
            text-align: center; }

        .copyright {
            img {
                margin-top: 0; }

            span.text-sm {
                margin-bottom: 12px; } }

        span {
            justify-content: center; } } }


@media screen and (max-width: 768px) {
    footer.fui-footer .copyright .MuiGrid-item {
        > img {
            margin: 0 auto 12px; }

        .fui-dropdown {
            width: 100%; } }

    footer.blank-footer {
        height: auto;

        .MuiGrid-root {
            order: 2;
            margin-bottom: $space * 3; }

        .links,
        footer.fui-footer .links {
            margin-top: 0; }

        .footer-lang {
            .fui-dropdown {
                float: none; } } } }




